import React, { ReactNode } from "react";
import { motion } from "framer-motion";
interface IMotionDivProps {
    customDiv: ReactNode;
    delay: number;
    x: number;
}
export const MotionFade = ({ customDiv, delay, x }: IMotionDivProps) => {
    return (
        <motion.div
            initial={{ x: x, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: delay, duration: 0.8 }}
        >
            {customDiv}
        </motion.div>
    );
};

interface IMotionFadeImageProps {
    imageUrl: string;
}

export const MotionFadeImage = ({ imageUrl }: IMotionFadeImageProps) => {
    return (
        <motion.div
            initial={{ x: 0, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4, duration: 0.8 }}
            className="overflow-hidden flex justify-center border-[#9b774e] border-2 "
        >
            <img
                src={imageUrl}
                alt="Features"
                className="h-full min-h-500px lg:max-w-xl object-cover m-auto"
            ></img>
        </motion.div>
    );
};
