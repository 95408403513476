const FacebookSvg = () => {
    return (
        <svg
            className="w-3 h-7 hidden md:block" //hover:-translate-y-7 transition-linear duration-200
            // viewBox="-5 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
        >
            {/* <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    ></g> */}
            <g id="SVGRepo_iconCarrier">
                {" "}
                <title>facebook [#ffffff]</title>{" "}
                <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    {" "}
                    <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-385.000000, -7399.000000)"
                        fill="#ffffff"
                    >
                        {" "}
                        <g
                            id="icons"
                            transform="translate(56.000000, 160.000000)"
                        >
                            {" "}
                            <path
                                d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z"
                                id="facebook-[#ffffff]"
                            >
                                {" "}
                            </path>{" "}
                        </g>{" "}
                    </g>{" "}
                </g>{" "}
            </g>
        </svg>
    );
};

export default FacebookSvg;
