import React from "react";
import ServicingHero from "../../components/serviceComponents/servicing/ServicingHero";
import ServicingFeatures from "../../components/serviceComponents/servicing/ServicingFeatures";
import ServicingFAQ from "../../components/serviceComponents/servicing/ServicingFAQ";

const Servicing = () => {
    return (
        <div>
            <div className="pt-20 md:pt-24 lg:pt-36">
                <ServicingHero />
            </div>
            <div id="servicingFeatures"></div>
            <div id="servicingFeaturesMobile" className="mt-20"></div>
            <div className="mt-36">
                <ServicingFeatures />
            </div>
            <div className="mt-36">
                <ServicingFAQ />
            </div>
        </div>
    );
};

export default Servicing;
