const ContactSentSvg = () => {
    return (
        <svg
        xmlns='http://www.w3.org/2000/svg'
        className={`h-7 w-7 ml-11`}
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M5 13l4 4L19 7' />
      </svg>
    );
  };
  
  export default ContactSentSvg;
  