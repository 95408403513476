import React from "react";
import MechanicalAndElectricalRepairsFeatures from "../../components/serviceComponents/mechanicalAndElectricalRepairs/MechanicalAndElectricalRepairsFeatures";
import MechanicalAndElectricalRepairsHero from "../../components/serviceComponents/mechanicalAndElectricalRepairs/MechanicalAndElectricalRepairsHero";
import MechanicalAndElectricalRepairsFAQ from "../../components/serviceComponents/mechanicalAndElectricalRepairs/MechanicalAndElectricalRepairsFAQ";

const MechanicalAndElectricalRepairs = () => {
    return (
        <div>
            <div className="pt-20 md:pt-24 lg:pt-36">
                <MechanicalAndElectricalRepairsHero />
            </div>
            <div id="mechanicalAndElectricalRepairsFeatures"></div>
            <div
                id="mechanicalAndElectricalRepairsFeaturesMobile"
                className="mt-20"
            ></div>
            <div className="mt-36">
                <MechanicalAndElectricalRepairsFeatures />
            </div>
            <div className="mt-36">
                <MechanicalAndElectricalRepairsFAQ />
            </div>
        </div>
    );
};

export default MechanicalAndElectricalRepairs;
