import React from "react";
import DetailingHero from "../../components/serviceComponents/detailing/DetailingHero";
import DetailingFeatures from "../../components/serviceComponents/detailing/DetailingFeatures";
import DetailingFAQ from "../../components/serviceComponents/detailing/DetailingFAQ";

const Detailing = () => {
    return (
        <div>
            <div className="pt-20 md:pt-24 lg:pt-36">
                <DetailingHero />
            </div>
            <div id="detailingFeatures"></div>
            <div id="detailingFeaturesMobile" className="mt-20"></div>
            <div className="mt-36">
                <DetailingFeatures />
            </div>
            <div className="mt-36">
                <DetailingFAQ />
            </div>
        </div>
    );
};

export default Detailing;
