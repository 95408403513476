import React from "react";
import PinkBlueSlipHero from "../../components/serviceComponents/pinkBlueSlip/PinkBlueSlipHero";
import PinkBlueSlipFeatures from "../../components/serviceComponents/pinkBlueSlip/PinkBlueSlipFeatures";
import PinkBlueSlipFAQ from "../../components/serviceComponents/pinkBlueSlip/PinkBlueSlipFAQ";

const PinkBlueSlip = () => {
    return (
        <div>
            <div className="pt-20 md:pt-24 lg:pt-36">
                <PinkBlueSlipHero />
            </div>
            <div id="pinkBlueSlipFeatures"></div>
            <div id="pinkBlueSlipFeaturesMobile" className="mt-20"></div>
            <div className="mt-36">
                <PinkBlueSlipFeatures />
            </div>
            <div className="mt-36">
                <PinkBlueSlipFAQ />
            </div>
        </div>
    );
};

export default PinkBlueSlip;
