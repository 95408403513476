import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import map from "../maps_picture.png";
import emailjs, { EmailJSResponseStatus } from "emailjs-com";
import Config from "./configs/ContactFormConfig.json";
import ContactAddressSvg from "../elements/svgs/ContactAddressSvg";
import ContactPhoneSvg from "../elements/svgs/ContactPhoneSvg";
import ContactEmailSvg from "../elements/svgs/ContactEmailSvg";
import ContactSendingSvg from "../elements/svgs/ContactSendingSvg";
import ContactSentSvg from "../elements/svgs/ContactSentSvg";

interface EmailSentForm {
    fullName: string;
    email: string;
    phoneNumber: string;
    make: string;
    model: string;
    year: string;
    kilometers: string;
    registration: string;
    problemDescription: string;
}

export const ContactForm = () => {
    const initialValues: EmailSentForm = {
        fullName: "",
        email: "",
        phoneNumber: "",
        make: "",
        model: "",
        year: "",
        kilometers: "",
        registration: "",
        problemDescription: "",
    };
    const [showModal, setShowModal] = React.useState(false);
    const [emailSending, setEmailSending] = useState(false);

    const handleSubmit = async (values: EmailSentForm, { resetForm }: any) => {
        setShowModal(true);
        setEmailSending(true);
        try {
            // Send the form data to the backend server
            const response: EmailJSResponseStatus = await emailjs.send(
                "service_jqbun5m",
                "template_eaqg49n",
                values as unknown as Record<string, unknown>,
                "_kEbVDEEft1sAZ8DU"
            );
            console.log(response);
            setEmailSending(false);
            setTimeout(() => {
                setShowModal(false);
            }, 4000);
            // Handle the response from the server
            resetForm();
        } catch (error) {
            // Handle any errors that occur during the request
            console.error("Error:", error);
        }
    };
    return (
        <section className="bg-black">
            <div className="grid max-w-7xl m-auto grid-cols-1 px-10 gap-2 mx-auto md:grid-cols-2 md:divide-x md:divide-y-0 divide-y text-white">
                <div className="py-8 md:py-0 md:px-6">
                    <h1 className="text-4xl sm:text-6xl font-bold text">
                        Get in touch
                    </h1>
                    <p className="pt-4 pb-8">
                        Fill in the form to start a conversation
                    </p>
                    <div className="space-y-4">
                        <p className="flex items-center">
                            <ContactAddressSvg />
                            <span>{Config.Address}</span>
                        </p>
                        <p className="flex items-center">
                            <ContactPhoneSvg />
                            <span>{Config.Phone}</span>
                        </p>
                        <p className="flex items-center pb-5">
                            <ContactEmailSvg />
                            <span>{Config.Email}</span>
                        </p>
                        <a href="https://www.google.com/maps/dir//cleveland+prestige/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x6b12a5df95ed3279:0xa76b23f5ba34c58d?sa=X&ved=2ahUKEwi1xPjAtbiAAxVhmVYBHSL6DNMQ9Rd6BAhCEAA&ved=2ahUKEwi1xPjAtbiAAxVhmVYBHSL6DNMQ9Rd6BAhHEAQ">
                            <img
                                src={map}
                                alt="map"
                                className="-bl-2xl -tr-2xl -tl-2xl -br-2xl border-4 border-solid border-[#9b774e]"
                            />
                        </a>
                    </div>
                </div>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    <Form className="flex flex-col py-6 space-y-2 md:py-0 md:px-6">
                        <h1 className="text-4xl font-bold text-left">
                            Make a booking online
                        </h1>
                        <label className="block text-left">
                            <div className="mb-2">
                                <span className="font-medium text-white">
                                    Full name{" "}
                                </span>
                            </div>
                            <Field
                                type="text"
                                name="fullName"
                                className="bg-gray-50 border-2 text-black text-sm -lg focus:border-[#9b774e] block w-full p-2.5 border-solid border-[#9b774e]"
                                placeholder="John Doe"
                                required
                            />
                        </label>
                        <label className="block text-left">
                            <div className="mb-2">
                                <span className="font-medium text-white">
                                    Email{" "}
                                </span>
                            </div>
                            <Field
                                type="email"
                                name="email"
                                className="bg-gray-50 border-2 border-[#9b774e] text-black text-sm -lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="john.doe@company.com"
                                required
                            />
                        </label>
                        <label className="block text-left">
                            <div className="mb-2">
                                <span className="font-medium text-white">
                                    Phone Number{" "}
                                </span>
                            </div>
                            <Field
                                type="text"
                                name="phoneNumber"
                                className="bg-gray-50 border-2 border-[#9b774e] text-black text-sm -lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="(04)XX XXX XXX"
                                required
                            />
                        </label>
                        <h1 className="text-2xl font-bold text-left">
                            Vehicle Details
                        </h1>
                        <div className="grid gap-6 pb-6 md:pb-0 md:grid-cols-2">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-white dark:text-white text-left">
                                    Make
                                </label>
                                <Field
                                    type="text"
                                    name="make"
                                    className="bg-gray-50 border-2 border-[#9b774e] text-black text-sm -lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-white dark:text-white text-left">
                                    Model
                                </label>
                                <Field
                                    type="text"
                                    name="model"
                                    className="bg-gray-50 border-2 border-[#9b774e] text-black text-sm -lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                />
                            </div>
                        </div>
                        <div className="grid gap-6 mb-6 md:grid-cols-3">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-white dark:text-white text-left">
                                    Year
                                </label>
                                <Field
                                    type="text"
                                    name="year"
                                    className="bg-gray-50 border-2 border-[#9b774e] text-black text-sm -lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-white dark:text-white text-left">
                                    Kilometers
                                </label>
                                <Field
                                    type="text"
                                    name="kilometers"
                                    className="bg-gray-50 border-2 border-[#9b774e] text-black text-sm -lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-white dark:text-white text-left">
                                    Registration/VIN
                                </label>
                                <Field
                                    type="text"
                                    name="registration"
                                    className="bg-gray-50 border-2 border-[#9b774e] text-black text-sm -lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                />
                            </div>
                        </div>
                        <label className="block text-left">
                            <div className="mb-2">
                                <span className="font-medium text-white">
                                    How can we help?
                                </span>
                            </div>
                            <Field
                                name="problemDescription"
                                className="block w-full p-3 text-black border-2 border-[#9b774e] -lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Tell us a little about the problem"
                                required
                                component="textarea"
                            />
                        </label>
                        <div className="mt-4">
                            <button
                                type="submit"
                                className="transition ease-in-out duration-500 bg-transparent hover:bg-[#9b774e] w-full text-white font-semibold hover:text-white py-2 px-4 mr-3 mt-4 border-2 border-[#9b774e] hover:border-transparent "
                            >
                                Message Us
                            </button>
                        </div>
                    </Form>
                </Formik>
            </div>
            <div
                className={`fixed bottom-4 left-4 text-white p-2 rounded shadow-lg z-50 ease-in-out duration-500 transition-opacity ${
                    showModal ? "opacity-100" : "opacity-0 pointer-events-none"
                }`}
            >
                <div className="flex items-center">
                    <p className="flex items-center text-[#059669] bg-[#D1FAE5] text-center rounded-full px-4 py-1">
                        {emailSending ? "Email Sending..." : "Email Sent"}
                        {emailSending ? (
                            <ContactSendingSvg />
                        ) : (
                            <ContactSentSvg />
                        )}
                    </p>
                </div>
            </div>
        </section>
    );
};
