import React, { useEffect } from "react";
import HeroBanner from "../components/HeroBanner";
import Navbar from "../components/Navbar";
import GalleryCarousel from "../components/GalleryCarousel";
import { motion } from "framer-motion";

import ServicesCarousel from "../components/ServicesCarousel";
import AboutBanner from "../components/AboutBanner";
import { delay } from "../services/utilities";
import FeaturesBanner from "../components/FeaturesBanner";
const HomePage = () => {
    const ABOUT_DELAY = 750;

    useEffect(() => {
        if (window.location.href.indexOf("about") > -1) {
            delay(ABOUT_DELAY).then(() => {
                const about = document.getElementById("about");
                about?.scrollIntoView({ behavior: "smooth" });
            });
        } else if (window.location.href.indexOf("services") > -1) {
            delay(ABOUT_DELAY).then(() => {
                const servicesString =
                    window.innerWidth < 768
                        ? "featuresBannerMobile"
                        : "featuresBanner";
                        
                const services = document.getElementById(servicesString);
                services?.scrollIntoView({ behavior: "smooth" });
            });
        }
    });

    return (
        <div style={{ backgroundColor: "black" }}>
            <HeroBanner />
            <div id="about"></div>
            <div className="mt-16 md:mt-32">
                <AboutBanner />
            </div>
            <div id="featuresBanner"></div>
            <div id="featuresBannerMobile" className="mt-20"></div>
            <div className="mt-40">
                <FeaturesBanner />
            </div>
            <div id="servicesCarousel" className="mb-20"></div>
            <div id="servicesCarouselMobile" className="mb-20"></div>
            <div className="mt-40">
                <ServicesCarousel />
            </div>
        </div>
    );
};

export default HomePage;
