import React from "react";
import ExteriorBodyWorkHero from "../../components/serviceComponents/exteriorBodyWork/ExteriorBodyWorkHero";
import ExteriorBodyWorkFeatures from "../../components/serviceComponents/exteriorBodyWork/ExteriorBodyWorkFeatures";
import ExteriorBodyWorkFAQ from "../../components/serviceComponents/exteriorBodyWork/ExteriorBodyWorkFAQ";

const ExteriorBodyWork = () => {
    return (
        <div>
            <div className="pt-20 md:pt-24 lg:pt-36">
                <ExteriorBodyWorkHero />
            </div>
            <div id="exteriorBodyWorkFeatures"></div>
            <div id="exteriorBodyWorkFeaturesMobile" className="mt-20"></div>
            <div className="mt-36">
                <ExteriorBodyWorkFeatures />
            </div>
            <div className="mt-36">
                <ExteriorBodyWorkFAQ />
            </div>
        </div>
    );
};

export default ExteriorBodyWork;
