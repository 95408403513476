import { ReactNode } from "react";
import AliceCarousel from "react-alice-carousel";
import CarouselItem from "./GalleryCarouselItem";
import {
    GalleryCarouselLeftButtonsSvg,
    GalleryCarouselRightButtonsSvg,
} from "../elements/svgs/GalleryCarouselLeftRightButtonsSvg";
import "react-alice-carousel/lib/alice-carousel.css";
import { motion } from "framer-motion";

interface IMotionDivProps {
    customDiv: ReactNode;
    delay: number;
}

const GalleryCarousel = () => {
    const items = [
        <CarouselItem
            CarouselItemText=""
            CarouselItemImage="https://cdn.motor1.com/images/mgl/WO7qr/s3/bugatti-chiron-super-sport-300-exterior.webp"
        />,
        <CarouselItem
            CarouselItemText=""
            CarouselItemImage="https://static.jdmexport.com/wp-content/uploads/sites/9/2021/12/07090429/Nissan-Skyline-R34.jpeg"
        />,
        <CarouselItem
            CarouselItemText=""
            CarouselItemImage="https://assets.whichcar.com.au/image/upload/s--MCzROGgZ--/c_fill,f_auto,q_auto:good/c_scale,w_768/v1/archive/whichcar-media/2407/bugatti-chiron-front-side.jpg"
        />,
        <CarouselItem
            CarouselItemText=""
            CarouselItemImage="https://assets.whichcar.com.au/image/upload/s--zdwl44N3--/c_fill,f_auto,q_auto:good/t_p_social/v1/archive/whichcar/2020/01/15/-1/10-Cool-Cars-Australia-Needs.jpg"
        />,
        <CarouselItem
            CarouselItemText=""
            CarouselItemImage="https://cdn.wallpapersafari.com/38/58/9awJnD.jpg"
        />,
        <CarouselItem
            CarouselItemText=""
            CarouselItemImage="https://cdn.motor1.com/images/mgl/KjMYQ/s3/bugatti-divo-exterior.webp"
        />,
        <CarouselItem
            CarouselItemText=""
            CarouselItemImage="https://images.unsplash.com/photo-1566008885218-90abf9200ddb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y29vbCUyMGNhcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
        />,
        <CarouselItem
            CarouselItemText=""
            CarouselItemImage="https://repairsmith-prod-wordpress.s3.amazonaws.com/2023/01/iStock-1399413280-1.jpg"
        />,
        <CarouselItem
            CarouselItemText=""
            CarouselItemImage="https://miro.medium.com/v2/resize:fit:1400/0*1kg5subxAEBeLvnO.jpg"
        />,
    ];

    const responsiveOption = {
        0: {
            items: 1,
        },
        550: {
            items: 2,
        },
        900: {
            items: 3,
            itemsFit: "fill",
        },
        1400: {
            items: 4,
            itemsFit: "fill",
        },
        2000: {
            items: 5,
            itemsFit: "fill",
        },
        2700: {
            items: 6,
            itemsFit: "fill",
        },
    };

    return (
        <div className="relative overflow-hidden">
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
                <AliceCarousel
                    mouseTracking
                    items={items}
                    responsive={responsiveOption}
                    autoPlay={true}
                    autoPlayInterval={4500}
                    infinite={true}
                    autoHeight={true}
                    disableDotsControls={true}
                    renderPrevButton={() => {
                        return (
                            <div className="absolute left-0 top-[115px]">
                                <div className="absolute cursor-pointer -left-8 border-0 p-8 rounded-full bg-gray-800 hover:bg-gray-900 bg-opacity-60 ease-in duration-100"></div>
                                <div className="absolute top-[19.5px] cursor-pointer pointer-events-none">
                                    <GalleryCarouselLeftButtonsSvg />
                                </div>
                            </div>
                        );
                    }}
                    renderNextButton={() => {
                        return (
                            <div className="absolute right-0 top-[115px]">
                                <div className="absolute cursor-pointer -right-8 border-0 p-8 rounded-full bg-gray-800 hover:bg-gray-900 bg-opacity-60 ease-in duration-100"></div>
                                <div className="absolute top-[19.5px] right-0 cursor-pointer pointer-events-none">
                                    <GalleryCarouselRightButtonsSvg />
                                </div>
                            </div>
                        );
                    }}
                />
            </motion.div>
        </div>
    );
};

export default GalleryCarousel;
