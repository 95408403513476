import React from "react";
import DetailingFeaturesImage from "../../../resources/images/services/detailing/DetailingFeatures.png";
import DetailingServiceBulletPointImage from "../../../resources/images/services/BulletPointImage.png";
import Config from "./DetailingConfig.json";
import { motion } from "framer-motion";
import { MotionFade, MotionFadeImage } from "../../../services/animations";

const DetailingFeatures = () => {
    const FeaturesTitle = (
        <h1 className="text-white font-medium rounded-lg text-xl md:text-3xl my-5 lg:my-0">
            {Config.Features.Title}
        </h1>
    );

    const FeaturesPoints = () => (
        <ul className="ml-2 sm:ml-6 my-6 space-y-4 text-xl">
            {Config.Features.Features.map((feature, index) => {
                return (
                    <motion.div
                        initial={{ x: -100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.2 + 0.1 * index, duration: 0.5 }}
                        key={index}
                    >
                        <li className="flex items-center space-x-2">
                            <img
                                src={DetailingServiceBulletPointImage}
                                className="w-4 h-4"
                                alt="Bullet Point"
                            />
                            <span className="text-white font-small">
                                {feature}
                            </span>
                        </li>
                    </motion.div>
                );
            })}
        </ul>
    );

    const FeaturesButton = (
        <a href="/contact">
            <button
                type="button"
                className="text-white font-medium text-md px-4 py-2.5 mr-2 mb-0 bg-none border-2 border-[#9b774e] hover:bg-[#9b774e] transition ease-linear duration-200"
            >
                {Config.Features.Button}
            </button>
        </a>
    );

    return (
        <div className="bg-black flex align-center">
            <div className="max-w-7xl m-auto ">
                <div className="grid lg:grid-cols-2 gap-10 border-0 mx-10 md:mx-20">
                    <MotionFadeImage imageUrl={DetailingFeaturesImage} />
                    <div className="relative min-h-[400px]">
                        <MotionFade
                            customDiv={FeaturesTitle}
                            delay={0}
                            x={-100}
                        />
                        <FeaturesPoints />
                        <MotionFade
                            customDiv={FeaturesButton}
                            delay={1}
                            x={0}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailingFeatures;
