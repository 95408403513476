import React from "react";
import { ContactForm } from "../components/ContactForm";

const ContactPage = () => {
    return (
        <div className="pt-20 md:pt-24 lg:pt-32">
            <ContactForm />
        </div>
    );
};

export default ContactPage;
