import React from "react";
import ModificationsHero from "../../components/serviceComponents/modifications/ModificationsHero";
import ModificationsFeatures from "../../components/serviceComponents/modifications/ModificationsFeatures";
import ModificationsFAQ from "../../components/serviceComponents/modifications/ModificationsFAQ";

const Modifications = () => {
    return (
        <div>
            <div className="pt-20 md:pt-24 lg:pt-36">
                <ModificationsHero />
            </div>
            <div id="modificationsFeatures"></div>
            <div id="modificationsFeaturesMobile" className="mt-20"></div>
            <div className="mt-36">
                <ModificationsFeatures />
            </div>
            <div className="mt-36">
                <ModificationsFAQ />
            </div>
        </div>
    );
};

export default Modifications;
